import { clear } from "@testing-library/user-event/dist/clear";
// import Payment from "payment";

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function formatExpirationDate(val) {
  const clearValue = clearNumber(val);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }
  return clearValue;
}
